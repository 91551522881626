import styled from "@emotion/styled";
import React, { FC } from "react";
import { rem, theme } from "../styling/theme";
import FlexBox from "../ui/FlexBox";
import FullSizeLoader from "../ui/FullSizeLoader";
import PageWrapper from "../ui/PageWrapper";

const Wrapper = styled(FlexBox)({
  position: "absolute",
  top: 0,
  left: 0,
  height: "100vh",
});

const Title = styled.h1({
  color: theme.color.primaryColor,
  fontSize: rem(200),
  opacity: 0.2,
});

const Quote = styled.h3({
  color: theme.color.primaryColor,
  fontSize: rem(30),
  marginBottom: rem(6),
  opacity: 0.8,
});

const Link = styled.a({
  color: theme.color.primaryColor,
  fontSize: rem(18),
  fontWeight: 600,
  opacity: 1,
  textDecoration: "none",
});

const Page404: FC = () => {
  return (
    <PageWrapper>
      <FullSizeLoader />
      <Wrapper fullWidth={true}>
        <Title>{"404"}</Title>
      </Wrapper>
      <Wrapper fullWidth={true} flexDirection="column">
        <Quote>{"This interesting path was not exposed yet..."}</Quote>
        <Link href="/">{"Try it again here."}</Link>
      </Wrapper>
    </PageWrapper>
  );
};

export default Page404;
